import React from 'react'
import  AccordionFaq  from './AccordionFaq';
import ReachOutNow from './ReachOutNow';
import { Element } from 'react-scroll';


function Faq() {
  return (
    <Element name="/#faqs" className='flex flex-col'>
    <AccordionFaq/>
    <ReachOutNow/>
    </Element>
  )
}

export default Faq
