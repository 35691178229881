import { Title, Container, Accordion } from '@mantine/core';
import classes from './Accordion.module.css';
import plus from "../assets/plus.png"
import minus from "../assets/minus.png"
import { useEffect, useState } from 'react';
import Aos from "aos";
import "aos/dist/aos.css";



const placeholder =
  "The purpose of the Accel234 real estate ERP system is to streamline workflows and foster continuous growth within real estate organizations. By implementing this software, companies aim to elevate their service standards and deliver an enhanced experience to clients through automation and efficiency."
const placeholder1 =
  "Our ERP system is designed to streamline and optimize various aspects of real estate business operations, including property management, finance, HR, CRM, supply chain, and inventory."
const placeholder2 =
  "Yes, the Accel234 ERP system is customizable to accommodate various business sizes. It can be adjusted over time to meet the specific needs of both customers and staff, whether scaling up for expansion or scaling down for efficiency."
const placeholder3 =
  "Accel234 is industry-tailored, affordable, and locally-built ERP system that caters specifically to real estate needs ensuring that it aligns perfectly with the requirements of businesses in this sector. Its accessibility and customization options, make it a standout choice in the market."


export default function AccordionFaq() {
  useEffect(() => {
    Aos.init({ duration: 3000 });
  }, []);
  const [expandedItems, setExpandedItems] = useState(["reset-password"]);

  const handleAccordionChange = (values) => {
    setExpandedItems(values);
  };

  const isItemExpanded = (value) => expandedItems?.includes(value);
  return (
    <main data-aos="fade-up-right">
      <div className={classes.wrapper}>
        {/* <h2 className='text-[#101828] text-[2.2rem] text-center font-medium py-0 my-0'>Frequently Asked Questions</h2> */}
        <Container size="sm">
          <Title ta="center" className={classes.title}>
            Frequently Asked Questions
          </Title>

          <Accordion

            chevronPosition="left"
            defaultValue="reset-password"
            chevronSize={26}
            variant="separated"
            // disableChevronRotation
            styles={{ label: { color: 'var(--mantine-color-black)' }, item: { border: 0 } }}
            onChange={handleAccordionChange}
            values={expandedItems}
          // chevron={
          //   <img src={plus} alt="plus"/>
          // }
          >
            <Accordion.Item className={classes.item} value="reset-password">
              <Accordion.Control >
                <div className='flex flex-row items-center px-4 gap-4'>
                  <img src={isItemExpanded('reset-password') ? minus : plus} alt="minus" className="transition-transform transform duration-300 ease-in-out cursor-pointer size-6" />
                  What is the purpose of Accel234 real estate ERP system?</div>
              </Accordion.Control>
              <Accordion.Panel>{placeholder}</Accordion.Panel>
            </Accordion.Item>

            <Accordion.Item className={classes.item} value="another-account">
              <Accordion.Control>
                <div className='flex flex-row items-center px-4 gap-4'>
                  <img src={isItemExpanded("another-account") ? minus : plus} alt="minus" className="transition-transform transform duration-300 ease-in-out cursor-pointer size-6" />
                  What operation does Accel234 perform?</div>
              </Accordion.Control>
              <Accordion.Panel>{placeholder1}</Accordion.Panel>
            </Accordion.Item>

            <Accordion.Item className={classes.item} value="newsletter">
              <Accordion.Control>
                <div className='flex flex-row items-center px-4 gap-4'>
                  <img src={isItemExpanded("newsletter") ? minus : plus} alt="minus" className="transition-transform transform duration-300 ease-in-out cursor-pointer size-6" />
                  Is Accel234 ERP system customizable for different business sizes?</div>
              </Accordion.Control>
              <Accordion.Panel>{placeholder2}</Accordion.Panel>
            </Accordion.Item>

            <Accordion.Item className={classes.item} value="credit-card">
              <Accordion.Control>
                <div className='flex flex-row items-center px-4 gap-4'>
                  <img src={isItemExpanded("credit-card") ? minus : plus} alt="minus" className="transition-transform transform duration-300 ease-in-out cursor-pointer size-6" />
                  What sets Accel234 real estate ERP system apart from others in the market?</div>
              </Accordion.Control>
              <Accordion.Panel>{placeholder3}</Accordion.Panel>
            </Accordion.Item>

            {/* <Accordion.Item className={classes.item} value="payment">
              <Accordion.Control>
                <div className='flex flex-row items-center px-4 gap-4'>
                  <img src={isItemExpanded("payment") ? minus : plus} alt="minus" className="transition-transform transform duration-300 ease-in-out cursor-pointer size-6" />
                  How does the Real Estate Module help in property management?</div>
              </Accordion.Control>
              <Accordion.Panel>{placeholder}</Accordion.Panel>
            </Accordion.Item> */}
          </Accordion>
        </Container>
      </div>
    </main>

  );
}