import { React} from "react";
import { AnimatePresence, motion } from "framer-motion";
import {
  Bars3Icon
} from "@heroicons/react/24/outline";
import Logo from "../assets/accelLogo.png";
import mobileLogo from "../assets/accelLogo.png";
import {  Link, useNavigate } from "react-router-dom";


const navLinks = [
  { name: "Features", href: "/", current: true, path:"/" },
    { name: "Solutions", href: "/", current: false, path:"/solutions" },
    { name: "FAQs", href: "/", current: false, path:"/faqs" },
    { name: "Packages", href: "/pricing", current: false, path:"/pricing" },
];

  const navigation = [
    { name: "Features", href: "/", current: true, path:"/" },
    { name: "Solutions", href: "/", current: false, path:"/solutions" },
    { name: "FAQs", href: "/", current: false, path:"/faqs" },
    { name: "Packages", href: "/pricing", current: false, path:"/pricing" },
    ];




const NavbarCopy = ({ open, setOpen }) => {
  const toggleMenu = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const menuVars = {
    initial: {
      scaleY: 0,
    },
    animate: {
      scaleY: 1,
      transition: {
        duration: 0.5,
        ease: [0.12, 0, 0.39, 0],
      },
    },
    exit: {
      scaleY: 0,
      transition: {
        delay: 0.5,
        duration: 0.5,
        ease: [0.22, 1, 0.36, 1],
      },
    },
  };
  const containerVars = {
    initial: {
      transition: {
        staggerChildren: 0.09,
        staggerDirection: -1,
      },
    },
    open: {
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.09,
        staggerDirection: 1,
      },
    },
  };

  // const location = useLocation();
  // const { pathname } = location;
  // const  pathName = pathname;

  const navigate = useNavigate();

  return (
    <header className=" w-full fixed top-0 left-0 z-[99]">
      <nav className="md:flex flex items-center  justify-between  py-4 px-2  lg:px-6 xl:px-[6.5rem] w-full relative bg-white ">
      <div className="cursor-pointer flex justify-between items-center px-2">
          <img
           onClick={() => navigate("/")}
            src={Logo}
            alt="logo"
            className="md:w-[6.875rem] md:h-[2.75rem] w-[3.75rem] h-[1.5rem] object-contain cursor-pointer"
          />
        </div>
        <Bars3Icon
          onClick={() => setOpen((prevValue) => !prevValue)}
          className="md:hidden block h-6 w-6 text-black cursor-pointer"
          aria-hidden="true"
        />
        <ul className="hidden md:flex md:items-center md:justify-center md:static md:w-auto  absolute top-0 right-0 text-[#101828] w-full gap-8">
          {navigation.map((link) => (
            <li key={link.name} className="text-2xl md:my-0 my-7">
              <Link
                className= "text-[#101828] text-[1.25rem] font-cabin font-medium hover:underline hover:underline-offset-2"
                to={link.href}
              >
                {link.name}
              </Link>
            </li>
          ))}
        </ul>

        <button onClick={() => {
            navigate("/getStarted");
          }} className="bg-[#149954] font-cabin font-semibold text-white px-4 p-2 rounded-lg hidden md:block">
          Get Started
        </button>
      </nav>
      <AnimatePresence>
        {open && (
          <motion.div
            variants={menuVars}
            initial="initial"
            animate="animate"
            exit="exit"
            className="md:hidden fixed left-0 top-0 w-full h-screen origin-top bg-white text-black p-10 z-50"
          >
            <div className="flex h-full flex-col">
              <div className="flex justify-between">
              <img
            src={mobileLogo}
            alt="logo"
            className=" w-[3.75rem] h-[1.5rem] object-contain"
          />
                <p
                  className="cursor-pointer text-md text-black"
                  onClick={toggleMenu}
                >
                  Close
                </p>
              </div>
              <motion.div
                variants={containerVars}
                initial="initial"
                animate="open"
                exit="initial"
                className="flex flex-col h-full justify-center font-lora items-center gap-4 "
              >
                {navLinks.map((link, index) => {
                  return (
                    <div  key={index} className="overflow-hidden">
                      <MobileNavLink
                        key={index}
                        title={link.name}
                        href={link.href}
                        open={open}
                        setOpen={setOpen}
                        path={link.path}
                      />
                    </div>
                  );
                })}
              </motion.div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </header>
  );
};

export default NavbarCopy;
const mobileLinkVars = {
  initial: {
    y: "30vh",
    transition: {
      duration: 0.5,
      ease: [0.37, 0, 0.63, 1],
    },
  },
  open: {
    y: 0,
    transition: {
      ease: [0, 0.55, 0.45, 1],
      duration: 0.7,
    },
  },
};
const MobileNavLink = ({ title, href, open, setOpen, path}) => {
  const navigate = useNavigate();
  return (
    <motion.div
      variants={mobileLinkVars}
      className="text-3xl  uppercase text-black"
    >
      {title === "Packages" ?
        <div
        className="cursor-pointer"
          onClick={() => { navigate(path); 
            setOpen((prevValue) => !prevValue); }}
        >
          {title}
        </div>
        :
        <div>
          <Link
            onClick={() => {
              setOpen((prevValue) => !prevValue);
            }}
            to={href}
          >
            {title}
          </Link>
        </div>}
    </motion.div>
  );
};
