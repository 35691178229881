import Navbar from "./components/NavBar";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import { useState } from "react";
import Footer from "./components/Footer";
import Pricing from "./pages/Pricing";
import ContactUs from "./pages/ContactUs";
import GetStarted from "./pages/GetStarted";
import NavbarCopy from "./components/NavBarCopy";

function App() {
  const [open, setOpen] = useState(false);
  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Navbar open={open} setOpen={setOpen} />
              {<Home />}
            </>
          }
        ></Route>
        <Route
          path="/pricing"
          element={
            <>
              <NavbarCopy open={open} setOpen={setOpen} />
              <Pricing />
            </>
          }
        />
        <Route
          path="/contactUs"
          element={
            <>
              <NavbarCopy open={open} setOpen={setOpen} />
              <ContactUs />
            </>
          }
        />
        <Route
          path="/getStarted"
          element={
            <>
              <NavbarCopy open={open} setOpen={setOpen} />
              <GetStarted />
            </>
          }
        />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
