import React, { useEffect } from 'react'
import house from "../assets/property.png"
import customer from "../assets/customer.png"
import humanCapital from "../assets/humanCapital.png"
import inventory from "../assets/inventory.png"
import supply from "../assets/supply.png"
import markerters from "../assets/markerters.png"
import payroll from "../assets/payroll.png"
import finance from "../assets/finance.png"
import construction from "../assets/construction.png"
import { Element } from 'react-scroll'
import Aos from "aos";
import "aos/dist/aos.css";
function AllInSolution() {


  useEffect(() => {
    Aos.init({ duration: 3000 });
  }, []);
  return (
    <Element name="/#solutions" className='flex flex-col relative'>
      <div className='flex items-center justify-center py-4 pb-10'>
        <h2 className='text-[#101828] leading-tight lg:text-[2.2rem] text-2xl text-center font-medium py-0 my-0 w-[30ch] font-roboto'>Your All-in-One Solution for Real Estate Excellence</h2>
      </div>
      <div className='grid lg:grid-cols-3 grid-cols-1 md:grid-cols-2 gap-4'>
        <div data-aos="fade-up-right" className='bg-[#FCFCFD] border-[#F2F4F7] border border-solid p-6 flex flex-col rounded-lg gap-2'>
          <img className='w-12 h-12' alt="property" src={house} />
          <h3 className='text-[#101828] font-roboto text-xl'>Property Management</h3>
          <p className="text-[#667085] font-cabin text-base">Effortlessly oversee your property portfolios, monitor transactions, and optimize space utilization with our Real Estate Management solution. Enhance property performance and streamline day-to-day operations.</p>
        </div>
        <div data-aos="fade-up" className='bg-[#FCFCFD] border-[#F2F4F7] border border-solid p-6 flex flex-col rounded-lg gap-2'>
          <img className='w-12 h-12' alt="property" src={customer} />
          <h3 className='text-[#101828] font-roboto text-xl'>Customer Relation Management</h3>
          <p className="text-[#667085] font-cabin text-base">Strengthen client relationships and boost customer satisfaction with our CRM solution. Centralize client data, track interactions, and create a more personalized experience.</p>
        </div>
        <div data-aos="fade-up-right" className='bg-[#FCFCFD] border-[#F2F4F7] border border-solid p-6 flex flex-col rounded-lg gap-2'>
          <img className='w-12 h-12' alt="property" src={humanCapital} />

          <h3 className='text-[#101828] font-roboto text-xl'>Human Capital Management</h3>
          <p className="text-[#667085] font-cabin text-base">Optimize workforce efficiency and employee engagement with our HCM solution. From recruitment to performance tracking, ensure your team is empowered for success.</p>
        </div>
        <div data-aos="fade-up" className='bg-[#FCFCFD] border-[#F2F4F7] border border-solid p-6 flex flex-col rounded-lg gap-2'>
          <img className='w-12 h-12' alt="property" src={supply} />
          <h3 className='text-[#101828] font-roboto text-xl'>Supply Management</h3>
          <p className="text-[#667085] font-cabin text-base">Simplify procurement, manage suppliers effectively, and ensure a streamlined supply chain with our Supply Management solution. Enhance operational efficiency and minimize costs.</p>
        </div>
        <div data-aos="fade-up-right" className='bg-[#FCFCFD] border-[#F2F4F7] border border-solid p-6 flex flex-col rounded-lg gap-2'>
          <img className='w-12 h-12' alt="property" src={finance} />
          <h3 className='text-[#101828] font-roboto text-xl'>Finance Report</h3>
          <p className="text-[#667085] font-cabin text-base">Access insightful and customizable financial reports to gain a clear understanding of your real estate business's financial health. Make informed decisions  with our Finance Reports solution.</p>
        </div>
        <div data-aos="fade-up" className='bg-[#FCFCFD] border-[#F2F4F7] border border-solid p-6 flex flex-col rounded-lg gap-2'>
          <img className='w-12 h-12' alt="property" src={inventory} />
          <h3 className='text-[#101828] font-roboto text-xl'>Inventory Report</h3>
          <p className="text-[#667085] font-cabin text-base">Efficiently manage your inventory with detailed and easily accessible reports. Stay on top of stock levels, reduce wastage, and optimize your resources with our Inventory Reports solution.</p>
        </div>
        <div data-aos="fade-up-right" className='bg-[#FCFCFD] border-[#F2F4F7] border border-solid p-6 flex flex-col rounded-lg gap-2'>
          <img className='w-12 h-12' alt="property" src={markerters} />
          <h3 className='text-[#101828] font-roboto text-xl'>Marketers Report</h3>
          <p className="text-[#667085] font-cabin text-base">Gain valuable insights into market trends and dynamics with our Market Reports solution. Make data-driven decisions and stay ahead in a competitive real estate landscape.</p>
        </div>
        <div data-aos="fade-up" className='bg-[#FCFCFD] border-[#F2F4F7] border border-solid p-6 flex flex-col rounded-lg gap-2'>
          <img className='w-12 h-12' alt="property" src={payroll} />
          <h3 className='text-[#101828] font-roboto text-xl'>Payroll</h3>
          <p className="text-[#667085] font-cabin text-base">Ensure accurate and timely payroll processing for your team. Our Payroll solution simplifies payroll management, providing a reliable and efficient way to compensate your workforce.</p>
        </div>
        <div data-aos="fade-up-right" className='bg-[#FCFCFD] border-[#F2F4F7] border border-solid p-6 flex flex-col rounded-lg gap-2'>
          <img className='w-12 h-12' alt="property" src={construction} />
          <h3 className='text-[#101828] font-roboto text-xl'>Construction</h3>
          <p className="text-[#667085] font-cabin text-base">Streamline construction project management with our dedicated solution. From project planning to execution, manage timelines, resources, and budgets effectively.</p>
        </div>

      </div>
    </Element>
  )
}

export default AllInSolution
