import React, { useEffect } from 'react'
import ReachOutNow from '../components/ReachOutNow'
import PricingHero from '../components/PricingHero'
import { useLocation } from 'react-router-dom';

function Pricing() {
    const location = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, [location]);

  return (
    <main className='mt-16 px-2  lg:px-6 xl:px-[6.5rem]'>
    <PricingHero/>
    <ReachOutNow/>
    </main>
  )
}

export default Pricing
