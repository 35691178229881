import React, { useEffect } from "react";
import elevate from "../assets/elevate.png";
import circleFrame from "../assets/HeroMain.png";
import { useNavigate } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import supply from "../assets/supply.png"
import finance from "../assets/finance.png"
import humanCapital from "../assets/humanCapital.png"
import house from "../assets/property.png"
import customer from "../assets/customer.png"
import { Element } from 'react-scroll';

function Hero() {
  const navigate = useNavigate();

  useEffect(() => {
    Aos.init({ duration: 3000 });
  }, []);


  return (
    <Element name="/#overview" id="/#overview" className="py-12 md:pt-36">
      <img
        src={circleFrame}
        alt="circle frame"
        className="absolute right-0 z-0 h-full w-full"
      />
      <section className="relative">
        <div data-aos="fade-up" className="flex flex-col gap-4 pb-8  justify-center items-center">
          <h1 className="text-[#101828] leading-tight lg:text-[2.25rem] text-2xl font-medium font-roboto lg:w-[28ch] text-center">
            Elevate Your Real Estate Business With accel234 Smart Solutions
          </h1>
          <p className="font-cabin text-[#667085] text-lg text-center lg:w-[65ch]">
            Experience next level real estate management – our ERP system
            intelligently integrates key processes, empowering you to make
            smarter decisions and drive success
          </p>

          <button
            onClick={() => {
              navigate("getStarted");
            }}
            className="bg-[#149954] font-cabin font-semibold text-[#FFFFFF] px-16 p-3 cursor-pointer rounded-lg  w-fit mx-auto my-0"
          >
            Get Started
          </button>
        </div>
      </section>

      <div data-aos="fade-up" className="flex justify-center items-center my-8 w-fit mx-auto  relative">
        <img src={elevate} alt="laptop and tablet" className="" />
        <div className='hidden lg:flex gap-4 items-center rounded-2xl border border-solid border-[#F2F4F7] px-3 py-1  xl:text-nowrap w-fit absolute lg:right-[-10%] bottom-36 bg-[#FCFCFD]'>
          <img src={supply} alt="supply" className="" />
          <p className='text-[#101828] font-medium font-cabin text-base'> Supply Module</p>
        </div>
        <div className='hidden lg:flex gap-4 items-center rounded-2xl border border-solid border-[#F2F4F7] px-3 py-1  xl:text-nowrap w-fit absolute lg:right-[-10%] top-36 bg-[#FCFCFD]'>
          <img src={humanCapital} alt="humanCapital" className=" " />
          <p className='text-[#101828] font-medium font-cabin text-base'>HCM Module</p>
        </div>
        <div className='hidden lg:flex gap-4 items-center rounded-2xl border border-solid border-[#F2F4F7] px-3 py-1 w-fit xl:text-nowrap absolute lg:left-[-10%] top-36 bg-[#FCFCFD]'>
          <img src={house} alt="property" className="" />
          <p className='text-[#101828] font-medium font-cabin text-base'> Real Estate Module</p>
        </div>
        <div className='hidden lg:flex gap-4 items-center rounded-2xl border border-solid border-[#F2F4F7] px-3 py-1  xl:text-nowrap w-fit absolute lg:left-[-10%] bottom-36 bg-[#FCFCFD]'>
          <img src={customer} alt="customer" className="" />
          <p className='text-[#101828] font-medium font-cabin text-base'>CRM Module</p>
        </div>
        <div className='hidden lg:flex gap-4 items-center rounded-2xl border border-solid border-[#F2F4F7] px-3 py-1 w-fit absolute bottom-[-3.5rem] bg-[#FCFCFD] mx-auto my-0'>
          <img src={finance} alt="supply" className="" />
          <div className="hidden lg:flex flex-col gap-2">
            <p className='text-[#101828] font-medium font-cabin text-base'>Finance Module</p>
            <p className='text-[#667085] font-cabin text-xs'>The HCM module automates tasks like payment</p>
          </div>
        </div>
      </div>
    </Element>
  );
}

export default Hero;
