import React, { useEffect } from "react";
import strategic from "../assets/strategic.png";
import finance from "../assets/FinancialManagement.png";
import customer from "../assets/customers.png";
import estate from "../assets/estateManagement.png";
import circleFrame from "../assets/CircleFrame.png";
import circleFrame1 from "../assets/Herosection.png";
import { Element } from 'react-scroll';
import Aos from "aos";
import "aos/dist/aos.css";

function OurFeatures() {
    useEffect(() => {
        Aos.init({ duration: 3000 });
    }, []);

    return (
        <Element name="/#features" id="/#features" className="flex flex-col pb-16">
            <div data-aos="fade-up" className="flex items-center justify-center py-4 pb-10 lg:pt-16">
                <h2 className="text-[#101828] leading-tight lg:text-[2.2rem] text-2xl text-center font-medium font-roboto">
                    Our Features
                </h2>
            </div>
            <section className="flex flex-col gap-6 lg:gap-10">
                <section data-aos="fade-up" className="rounded-lg bg-[#149954] border-[#EAECF0] border border-solid lg:p-12 p-4   relative">
                    <img src={circleFrame} alt="circle frame" className="absolute right-0 h-[96%] md:h-[93.3%]" />
                    <div className="flex flex-col gap-2 pb-8">
                        <h2 className="text-[#FFFFFF] leading-tight lg:text-[2.25rem] text-2xl font-medium font-roboto lg:w-[28ch]">
                            Optimize Property Management & Transaction
                        </h2>
                        <p className="font-cabin text-[#FFFFFF] text-base lg:w-[50ch]">
                            Effortlessly manage real estate properties, streamline transactions, and gain insights into property performance. Our Real Estate Module is tailored to meet the unique needs of property managers and real estate professionals. </p>
                    </div>
                    <img src={estate} alt="strategic workforce managemnet" className="relative" />
                </section>
                <section data-aos="fade-up" className="rounded-lg bg-[#FCFCFD] border-[#EAECF0] border border-solid lg:p-12 p-4   relative">
                    <img src={circleFrame1} alt="circle frame" className="absolute right-0 z-0 h-[96%] md:h-[93.3%]" />
                    <div className="flex flex-col gap-2 pb-8">
                        <h2 className="text-[#101828] leading-tight lg:text-[2.25rem] text-2xl font-medium font-roboto lg:w-[28ch]">
                            Drive Revenue with CRM Excellence
                        </h2>
                        <p className="font-cabin text-[#667085] text-base lg:w-[50ch]">
                            Build and nurture customer relationships with our CRM Module. Enhance client interactions, track leads, and optimize your sales funnel for sustained business growth. </p>
                    </div>
                    <img src={customer} alt="strategic workforce managemnet" className="relative" />
                </section>
                <section data-aos="fade-up" className="rounded-lg bg-[#149954] border-[#EAECF0] border border-solid lg:p-12 p-4   relative">
                    <img src={circleFrame} alt="circle frame" className="absolute right-0 z-0 h-[96%] md:h-[93.3%]" />

                    <div className="flex flex-col gap-2 pb-8">
                        <h2 className="text-[#FFFFFF] leading-tight lg:text-[2.25rem] text-2xl font-medium font-roboto lg:w-[28ch]">
                            Smart Financial Management for Real Estate Ventures
                        </h2>
                        <p className="font-cabin text-[#FFFFFF] text-base lg:w-[55ch]">
                            Gain control over your finances and ensure compliance. From budgeting to financial reporting, our solution is designed to meet the unique challenges of financial management in the real estate industry. </p>
                    </div>
                    <img src={finance} alt="strategic workforce managemnet" className="z-50 relative" />
                </section>
                <section data-aos="fade-up" className="rounded-lg bg-[#FCFCFD] border-[#EAECF0] border border-solid lg:p-12 p-4   relative">
                    <img src={circleFrame1} alt="circle frame" className="absolute right-0 z-0 h-[96%] md:h-[93.3%]" />
                    <div className="flex flex-col gap-2 pb-8 relative">
                        <h2 className="text-[#101828] leading-tight lg:text-[2.25rem] text-2xl font-medium font-roboto">
                            Strategic Workforce Management
                        </h2>
                        <p className="font-cabin text-[#667085] text-base lg:w-[50ch]">
                            Enhance employee productivity, optimize talent management, and drive business success with our Human Capital Management Module. </p>
                    </div>
                    <img src={strategic} alt="strategic workforce managemnet" className="relative" />
                </section>
            </section>
        </Element >
    );
}

export default OurFeatures;
