import React, { useEffect } from "react";
import Logo from "../assets/accelLogo.png";
import Copyright from "../assets/Copyright.png";
import twitter from "../assets/mdi_twitter.png";
import { Link as Linked,useNavigate } from "react-router-dom";
import { Link } from "react-scroll";
import Aos from "aos";
import "aos/dist/aos.css";
import { FaYoutube} from "react-icons/fa";

function Footer() {
  function getCurrentYear() {
    const currentDate = new Date(); // Creates a new date object representing the current date and time
    const currentYear = currentDate.getFullYear(); // Extracts the year from the date object
    return currentYear;
  }

  useEffect(() => {
    Aos.init({ duration: 3000 });
  }, []);

  const navigate = useNavigate()

  return (
    <footer className="py-10 lg:py-16 lg:px-6 xl:px-28 px-1 mx-0 w-full self-end mt-10 font-cabin">
      <div data-aos="fade-up" className="border-[#D0D5DD] border-solid border-t border-b flex  flex-col lg:flex-row justify-between py-8">
        <div className="flex flex-col gap-3 self-start">
          <img
          onClick={() => navigate("/")}
            src={Logo}
            alt="logo"
            className="md:w-[6.875rem] md:h-[2.75rem] w-[3.75rem] h-[1.5rem] object-contain cursor-pointer"
          />
          <p className="text-base text-[#667085] w-[30ch] font-cabin">
            We immersed ourselves in real estate and engaged stakeholders.
          </p>
        </div>
        <div className="lg:grid lg:grid-cols-2 lg:gap-10 flex flex-col pt-2 lg:pt-0">
          <div className="flex flex-col lg:gap-1  font-inter  md:order-2 order-2">
            <h3 className="font-cabin text-[#667085] text-base  font-base py-1 md:py-0">
              Product
            </h3>
            <div className="flex flex-col gap-1  md:py-0">
              <Link
                to="/#overview"
                smooth={true}
                duration={500}
                className="font-roboto text-[#101828] text-sm md:text-base font-medium cursor-pointer">
                Overview
              </Link>
              <Link
                to="/#features"
                smooth={true}
                duration={500}
                className="font-roboto text-[#101828] text-sm md:text-base font-medium cursor-pointer"
              >
                Features
              </Link>
              <Link
                to="/#solutions"
                smooth={true}
                duration={500}
                className="font-roboto text-[#101828] text-sm md:text-base font-medium cursor-pointer"
              >
                Solution
              </Link>
              <div className="flex gap-3">
                <Linked
                  to={"/pricing"}
                  className="font-roboto text-[#101828] text-sm md:text-base font-medium cursor-pointer"
                >
                  Pricing
                </Linked>
              </div>
            </div>
          </div>

          <div className="flex flex-col lg:gap-1  font-inter  md:order-2 order-2">
            <h3 className="font-cabin text-[#667085] text-base  font-base py-1 md:py-0">
              Contact us
            </h3>
            <div className="flex flex-col gap-1 py-0">
              <p className="font-roboto text-[#101828] text-sm  md:text-base font-medium">
                hello@accel234.com
              </p>
              <p
                className="font-roboto text-[#101828] text-sm md:text-base font-medium
                "
              >
                +234 703 243 5995
              </p>
            </div>
          </div>
        </div>
      </div>

      <div data-aos="fade-up" className="flex justify-between lg:flex-row flex-col gap-6 lg:gap-0 py-6">
        <div className="flex gap-1 items-center text-[#667085] order-2 lg:order-1">
          <img src={Copyright} alt="copyright" />
          <p className="font-cabin text-sm font-medium">
            {getCurrentYear()} accel234. All rights reserved
          </p>
        </div>
        <div className="flex gap-3 order-1 lg:order-2 items-center">
         
          <a href=" https://x.com/Accel234?t=NnzxC1n3A6TufQj1HDmafg&s=08"><img src={twitter} alt="twitter" /></a>
          <a href="https://youtube.com/@Accel234?si=uEgum0q7T3SJPLMl"><FaYoutube className="text-3xl text-[#667085]" /></a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
