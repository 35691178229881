import React from 'react'
import ReachOutNow from '../components/ReachOutNow'
import GetStartedHero from '../components/GetStartedHero'

function GetStarted() {
  return (
    <main className='mt-16 px-2  lg:px-6 xl:px-[6.5rem]'>
 <GetStartedHero/>
    <ReachOutNow/>
    </main>
  )
}

export default GetStarted
