import React, { useState } from 'react'
import circleFrame from "../assets/HeroMain.png"
import phone from "../assets/telephone.png"
import email from "../assets/email.png"
import emailjs from "@emailjs/browser";
import { useRef } from "react";
import { toast } from "react-toastify";

function Contact() {
    const [loading, setLoading] = useState(false)
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault(); // prevents the page from reloading when you hit “Send”
        setLoading(true)
        // emailjs.init("mt0sOPqJjb7043KKw");
        emailjs
            .sendForm(
                "service_sn6nwea","template_gff5e4l",
                form.current,
                "qn_E9slnmfRMUW2NR"
            )
            .then(
                (result) => {
                    toast.success("Message sent successfully");
                    form.current.reset();
                    setLoading(false)
                },
                (error) => {
                    toast.error(error.message);
                    setLoading(false)
                }
            );
    };

    return (
        <main className='my-16 lg:mt-36 relative'>
            <img src={circleFrame} alt="circle frame" className="absolute right-0 z-0 " />
            <section className='relative grid lg:grid-cols-2 grid-cols-1'>
                <div className='p-3 lg:p-0'>
                    <div className="flex flex-col gap-2 pb-8 ">
                        <h1 className="text-[#149954] text-2xl font-medium font-roboto">
                            Reach out
                        </h1>
                        <p className="font-cabin text-[#101828] lg:text-5xl text-2xl font-medium lg:w-[14ch] ">
                            Get in touch, let us know how we can help</p>
                    </div>
                    <div className='flex flex-col gap-6'>
                        <div className='flex  gap-6 items-center'>
                            <img src={email} alt="email" />
                            <p className='text-[#149954] font-cabin text-base font-medium'>hello@accel234.com</p>
                        </div>
                        <div className='flex  gap-6 items-center'>
                            <img src={phone} alt="phone" />
                            <p className='text-[#149954] font-cabin text-base font-medium'>hello@accel234.com</p>
                        </div>
                    </div>
                </div>

                <form ref={form}
                    onSubmit={sendEmail} className='shadow-md rounded-2xl lg:p-8 p-4 flex flex-col gap-6 border border-solid border-[#1018280F] w-full relative bg-white my-8 lg:my-0'>
                    <div className='flex gap-4 w-full flex-col lg:flex-row'>
                        <label className='flex flex-col gap-2 font-roboto text-[#101828] text-sm w-full'>
                            <p>Full Name <span className="text-red-700">*</span></p>
                            <input placeholder='Enter Your Full Name' className='rounded-[4px] px-6 py-3 border border-solid border-[#C9C5CA] placeholder:font-roboto text-[#787579] text-base' type="text"
                                name="from_name" required />
                        </label>
                        <label className='flex flex-col gap-2 font-roboto text-[#101828] text-sm w-full'>
                            <p>Email Address <span className="text-red-700">*</span></p>
                            <input placeholder='Enter Your Email Address' className='rounded-[4px] px-6 py-3 border border-solid border-[#C9C5CA] placeholder:font-roboto text-[#787579] text-base' type="email"
                                name="email_address" required />
                        </label>
                    </div>
                    <div className='flex gap-4 w-full flex-col lg:flex-row'>
                        <label className='flex flex-col gap-2 font-roboto text-[#101828] text-sm w-full'>
                            <p>Company Name <span className="text-red-700">*</span></p>
                            <input placeholder='Enter Your Company Name' className='rounded-[4px] px-6 py-3 border border-solid border-[#C9C5CA] placeholder:font-roboto text-[#787579] text-base' name="company_name" required />
                        </label>
                        <label className='flex flex-col gap-2 font-roboto text-[#101828] text-sm w-full'>
                            <p>Phone Number <span className="text-red-700">*</span></p>
                            <input placeholder='Enter Your Phone Number' className='rounded-[4px] px-6 py-3 border border-solid border-[#C9C5CA] placeholder:font-roboto text-[#787579] text-base' name="phone_number" required />
                        </label>
                    </div>


                    <label className='flex flex-col gap-2 font-roboto text-[#101828] text-sm w-full '>
                       <p>Message <span className="text-red-700">*</span></p>
                        <textarea rows={4} cols={40} placeholder='Enter your message here' className='rounded-[4px] border-[#C9C5CA] border border-solid p-4' name="message" required />
                    </label>

                    <div className='flex justify-center items-center'>
                        <button disabled={loading} type='submit' className="bg-[#149954] font-cabin font-semibold text-white px-8 p-2 rounded-lg disabled:opacity-30">
                            Submit
                        </button>
                    </div>

                </form>

            </section>

        </main>
    )
}

export default Contact
