import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { HashRouter as Router } from "react-router-dom";
import { MantineProvider } from '@mantine/core';
import '@mantine/core/styles.css';
import ScrollToTop from './utils/ScrollToTop';
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
     <Router>
     <ScrollToTop/>
     <ToastContainer/>
    < MantineProvider>
    <App />
    </MantineProvider>
    </Router>
  </React.StrictMode>
);


