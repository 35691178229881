import React, { useEffect } from 'react'
import circleFrame from "../assets/HeroMain.png"
import house from "../assets/property.png"
import customer from "../assets/customer.png"
import humanCapital from "../assets/humanCapital.png"
import supply from "../assets/supply.png"
import Aos from "aos";
import "aos/dist/aos.css";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup'
import { toast } from "react-toastify";


function GetStartedHero() {
    useEffect(() => {
        Aos.init({ duration: 3000 });
    }, []);


    const validationSchema = Yup.object({
        full_name: Yup.string().required('Full name is required'),
        email: Yup.string().email('Invalid email address').required('Email is required'),
        company_name: Yup.string().required('Company name is required'),
        company_size: Yup.string().required('Company size is required'),
        industry: Yup.string().required('Industry is required'),
        phone_number: Yup.string().required('Phone Number is required'),
        // Add other fields as necessary with appropriate validation
    });

    const initialValues = {
        full_name: "",
        email: "",
        company_name: "",
        company_size: "", // Make sure your initial values match the expected types
        industry: "",
        phone_number: "",
    };



    // Function to handle form submission
    const handleSubmit = async (values) => {
        // Create a FormData object
        const formDataObject = new FormData();
        formDataObject.append('full_name', values.full_name);
        formDataObject.append('company_name', values.company_name);
        formDataObject.append('email', values.email);
        formDataObject.append('company_size', values.company_size);
        formDataObject.append('industry', values.industry);
        formDataObject.append('phone_number', values.phone_number);


        // Example: Send FormData to an API
        try {
            const response = await fetch('https://consode.coinzonetrading.com/api/get-started', {
                method: 'POST',
                body: formDataObject,
            });

            if (response.ok) {
                toast.success('Form submitted successfully!');
            } else {
                toast.error('Error submitting form');
            }
        } catch (error) {
            toast.error('Error submitting form', error);
        }

    };

    return (
        <main className='my-16 lg:mt-36 relative flex flex-col'>
            <img data-aos="fade-up" src={circleFrame} alt="circle frame" className="absolute right-0 z-0 " />
            <section data-aos="fade-up" className='relative '>
                <div>
                    <div className="flex flex-col gap-2 pb-8 ">
                        <h1 className="font-roboto text-[#484649] lg:text-[3rem] text-2xl font-medium lg:w-[28ch] lg:leading-[57.6px] text-center mx-auto my-0">
                            You Are Just One Step Away From Transforming Your Real Estate Business </h1>
                    </div>

                    <div className=' mx-auto my-0 lg:w-1/2  mb-10 flex flex-col gap-4 items-center lg:items-start'>
                        <div className='flex gap-4 flex-col lg:flex-row'>
                            <div className='flex gap-4 items-center rounded-2xl border border-solid border-[#F2F4F7] px-3 py-1 w-fit xl:text-nowrap '>

                                <img src={house} alt="property" className="" />
                                <p className='text-[#484649] font-medium font-cabin text-sm'> Real Estate Management</p>
                            </div>
                            <div className='flex gap-4 items-center rounded-2xl border border-solid border-[#F2F4F7] px-3 py-1  xl:text-nowrap w-fit'>
                                <img src={customer} alt="customer" className="" />
                                <p className='text-[#484649] font-medium font-cabin text-sm'>Customer Relation Management</p>

                            </div>
                        </div>
                        <div className='flex gap-4 flex-col lg:flex-row'>
                            <div className='flex gap-4 items-center rounded-2xl border border-solid border-[#F2F4F7] px-3 py-1 lg:w-fit w-full'>
                                <img src={humanCapital} alt="humanCapital" className=" " />
                                <p className='text-[#484649] font-medium font-cabin text-sm'>Human Capital Management</p>

                            </div>
                            <div className='flex gap-4 items-center rounded-2xl border border-solid border-[#F2F4F7] px-3 py-1 lg:w-fit w-full'>
                                <img src={supply} alt="supply" className="" />
                                <p className='text-[#484649] font-medium font-cabin text-sm'> Supply Management</p>

                            </div>
                        </div>

                    </div>
                </div>

                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ isSubmitting }) => (

                        <Form className='shadow-md p-4 lg:p-8 pt-12 flex flex-col gap-8 border border-solid border-[#1018280F]  relative bg-white lg:w-3/4 my-0 lg:mx-auto'>
                            <div className="flex flex-col gap-2 lg:gap-0 ">
                                <h1 className="text-[#484649] lg:text-[2rem] text-2xl font-medium font-roboto">
                                    Get Started
                                </h1>
                                <p className="font-cabin text-[#101828] text-base font-medium ">
                                    Kindly fill the form below to get started</p>
                            </div>
                            <label className='flex flex-col gap-2 font-roboto text-[#101828] text-sm w-full'>
                                <p>Full Name <span className="text-red-700 inline">*</span></p>
                                <Field name="full_name" type="text" className='rounded-[4px] px-3 py-3 border border-solid border-[#C9C5CA] placeholder:font-roboto text-[#787579] text-base' placeholder='John Doe' />
                                <ErrorMessage name="full_name" component="div" className='text-red-700' />
                            </label>
                            <label className='flex flex-col gap-2 font-roboto text-[#101828] text-sm w-full'>
                                <p>Email Address <span className="text-red-700 inline">*</span></p> 
                                <Field name="email" type="email" className='rounded-[4px] px-3 py-3 border border-solid border-[#C9C5CA] placeholder:font-roboto text-[#787579] text-base' placeholder='johndoe@email.com' />
                                <ErrorMessage name="email" component="div" className='text-red-700' />
                            </label>
                            <label className='flex flex-col gap-2 font-roboto text-[#101828] text-sm w-full'>
                               <p> Name of Company <span className="text-red-700">*</span></p>
                                <Field name="company_name" type="text" className='rounded-[4px] px-3 py-3 border border-solid border-[#C9C5CA] placeholder:font-roboto text-[#787579] text-base' placeholder='accel234' />
                                <ErrorMessage name="company_name" component="div" className='text-red-700' />
                            </label>


                            <div className='flex gap-6 lg:flex-row flex-col'>
                                <label className='flex flex-col gap-2 font-roboto text-[#101828] text-sm w-full'>
                                    <p>Company Sizes <span className="text-red-700">*</span></p>
                                    <Field as="select" name="company_size" className='rounded-[4px] px-3 py-3 border border-solid border-[#C9C5CA] placeholder:font-roboto text-[#787579] text-base'>
                                        <option value="">Select Company Size</option>
                                        <option value="0-10">0-10</option>
                                        <option value="31-60">31-60</option>
                                        <option value="60-above">60-above</option>
                                    </Field>
                                    <ErrorMessage name="company_size" component="div" className='text-red-700' />

                                </label>
                                <label className='flex flex-col gap-2 font-roboto text-[#101828] text-sm w-full'>
                                <p>List of Industries <span className="text-red-700">*</span></p>
                                    <Field as="select" name="industry" className='rounded-[4px] px-3 py-3 border border-solid border-[#C9C5CA] placeholder:font-roboto text-[#787579] text-base'>
                                        <option value="">Select Industry</option>
                                        <option value="real-estate-agencies">Real Estate Agencies </option>
                                        <option value="property-management-companies">Property Management Companies</option>
                                        <option value="construction-and-management">Construction and Development</option>
                                        <option value="healthcare-facilities">Healthcare Facilities</option>
                                        <option value="hospitality-and-tourism">Hospitality and Tourism</option>
                                        <option value="education-institutions">Education Institutions</option>
                                        <option value="transportation-and-logistics">Transportation and Logistics</option>
                                        <option value="agriculture-and-farming">Agriculture and Farming</option>
                                        <option value="others">Others</option>
                                    </Field>
                                    <ErrorMessage name="industry" component="div" className='text-red-700' />
                                </label>
                            </div>
                            <label className='flex flex-col gap-2 font-roboto text-[#101828] text-sm w-full'>
                                <p>Phone Number <span className="text-red-700">*</span></p>
                                <Field name="phone_number" type="text" className='rounded-[4px] px-3 py-3 border border-solid border-[#C9C5CA] placeholder:font-roboto text-[#787579] text-base' placeholder='+234 901 234 567' />
                                <ErrorMessage name="phone_number" component="div" className='text-red-700' />
                            </label>



                            <div className='flex justify-center items-center'>
                                <button type='submit' disabled={isSubmitting} className="bg-[#149954] font-cabin font-semibold text-white px-8 p-2 rounded-lg disabled:opacity-30">
                                    Submit
                                </button>
                            </div>

                        </Form>
                    )}
                </Formik>
            </section>

        </main>
    )
}

export default GetStartedHero
