import React from 'react'
import GetStartedNow from '../components/GetStartedNow'
import Contact from '../components/Contact'

function ContactUs() {
  return (
    <main className='mt-16 px-2  lg:px-6 xl:px-[6.5rem]'>
    <Contact/>
    <GetStartedNow/>
    </main>
  )
}

export default ContactUs
