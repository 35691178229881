import React from 'react'
import circleFrame from "../assets/HeroMain.png"
import CheckIcon from "../assets/CheckIcon.png"
function PricingHero() {
  return (
    <main className='my-16 lg:mt-36 relative'>
    <img src={circleFrame} alt="circle frame" className="absolute right-0 z-0 h-full w-full"/>
        <section className='relative'>
            <div className="flex flex-col gap-4 pb-8  justify-center items-center">
                <h1 className="text-[#101828] leading-tight lg:text-[3rem] text-2xl font-medium font-roboto  text-center">
                Business Model
                </h1>
                <p className="font-cabin text-[#667085] text-lg text-center">
                Our business model has a simple and transparent pricing that grows with you. </p>
            </div>
        </section>

        <section className='grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6'>
        <div className='rounded-2xl border-[#EAECF0] border-solid border-2 pt-8 pb-20 px-8 bg-white relative'>
        <h2 className='text-center text-[#101828] text-2xl font-cabin pb-4'>Basic</h2>
        <p  className='font-cabin text-[#667085] text-base italic text-center'>*Excluding Payroll & Construction</p>
        <div className='flex flex-col gap-3 py-6'>
            <div className='flex gap-3 '>
            <img src={CheckIcon} alt="success"/>
            <p className='font-cabin text-[#667085] text-base'>Real Estate Module</p>
            </div>
            <div className='flex gap-3 '>
            <img src={CheckIcon} alt="success"/>
            <p className='font-cabin text-[#667085] text-base'>CRM Module</p>
            </div>
            <div className='flex gap-3 '>
            <img src={CheckIcon} alt="success"/>
            <p className='font-cabin text-[#667085] text-base'>HCM Module</p>
            </div>
            <div className='flex gap-3 '>
            <img src={CheckIcon} alt="success"/>
            <p className='font-cabin text-[#667085] text-base'>Finance Module</p>
            </div>
        </div>
        </div>
        <div className='rounded-2xl border-[#EAECF0] border-solid border-2 pt-8 pb-20 px-8 bg-white relative'>
        <h2 className='text-center text-[#101828] text-2xl font-cabin pb-4'>Professional</h2>
        <p  className='font-cabin text-[#667085] text-base italic text-center'>*Excluding Payroll </p>
        <div className='flex flex-col gap-3 py-6'>
            <div className='flex gap-3 '>
            <img src={CheckIcon} alt="success"/>
            <p className='font-cabin text-[#667085] text-base'>Real Estate Module</p>
            </div>
            <div className='flex gap-3 '>
            <img src={CheckIcon} alt="success"/>
            <p className='font-cabin text-[#667085] text-base'>CRM Module</p>
            </div>
            <div className='flex gap-3 '>
            <img src={CheckIcon} alt="success"/>
            <p className='font-cabin text-[#667085] text-base'>HCM Module</p>
            </div>
            <div className='flex gap-3 '>
            <img src={CheckIcon} alt="success"/>
            <p className='font-cabin text-[#667085] text-base'>Finance Module</p>
            </div>
            <div className='flex gap-3 '>
            <img src={CheckIcon} alt="success"/>
            <p className='font-cabin text-[#667085] text-base'>Inventory Module</p>
            </div>
        </div>
        </div>
        <div className='rounded-2xl border-[#EAECF0] border-solid border-2 pt-8 pb-20 px-8 bg-white relative'>
        <h2 className='text-center text-[#101828] text-2xl font-cabin pb-4'>Enterprise</h2>
        <p  className='font-cabin text-[#667085] text-base italic text-center'>Unlimited Access.</p>
        <div className='flex flex-col gap-3 py-6'>
            <div className='flex gap-3 '>
            <img src={CheckIcon} alt="success"/>
            <p className='font-cabin text-[#667085] text-base'>Real Estate Module</p>
            </div>
            <div className='flex gap-3 '>
            <img src={CheckIcon} alt="success"/>
            <p className='font-cabin text-[#667085] text-base'>CRM Module</p>
            </div>
            <div className='flex gap-3 '>
            <img src={CheckIcon} alt="success"/>
            <p className='font-cabin text-[#667085] text-base'>HCM Module</p>
            </div>
            <div className='flex gap-3 '>
            <img src={CheckIcon} alt="success"/>
            <p className='font-cabin text-[#667085] text-base'>Finance Module</p>
            </div>
            <div className='flex gap-3 '>
            <img src={CheckIcon} alt="success"/>
            <p className='font-cabin text-[#667085] text-base'>Inventory Module</p>
            </div>
            <div className='flex gap-3 '>
            <img src={CheckIcon} alt="success"/>
            <p className='font-cabin text-[#667085] text-base'>Supply  Module</p>
            </div>
        </div>
        </div>
        </section>
    </main>
  )
}

export default PricingHero
