import React from 'react'
import Chat  from  "../assets/chat.png";
import curvedLeft  from  "../assets/curvedLeft.png";
import curvedRight from  "../assets/curvedRight.png";
import { Link } from 'react-router-dom';
function ReachOutNow() {
  return (
    <main className='bg-[#149954] rounded-2xl flex items-center justify-center flex-col gap-3 h-96 relative'>
    <img src={Chat} alt='Chat'/>
    <img src={curvedLeft} alt='circle left'  className='absolute left-0 h-full '/>
    <img src={curvedRight} alt='circle right' className='absolute right-0 h-full '/>
    <div className='flex flex-col gap-6 text-center relative'>
    <h1 className='text-white font-medium font-roboto lg:text-[2.25rem] text-xl'>Still have questions?</h1>
    <p className='text-white font-cabin text-base lg:w-[45ch]'>Book a call with our team to learn how accel234 can help you optimize the way you manage your Real Estate business.</p>

    <Link to="/contactUs" className="bg-[#FAFEFC] font-cabin font-semibold text-[#149954] px-16 p-3 rounded-lg  w-fit mx-auto my-0">
         Reach Out
        </Link>
    </div>

    </main>
  )
}

export default ReachOutNow
