import React, { useEffect } from 'react'
import Faq from '../components/Faq'
import AllInSolution from '../components/AllInSolution'
import OurFeatures from '../components/OurFeatures'
import Hero from '../components/Hero'
import { useLocation } from 'react-router-dom';

function Home() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, [location]);
  return (
    <main className='mt-16 px-2  lg:px-6 xl:px-[6.5rem]'>
      <Hero/>
      <OurFeatures/>
      <AllInSolution/>
      <Faq/>
    </main>
  )
}

export default Home
