import React, { useEffect } from 'react'
import curvedLeft  from  "../assets/curvedLeft.png";
import curvedRight from  "../assets/curvedRight.png";
import { Link } from 'react-router-dom';
import Aos from "aos";
import "aos/dist/aos.css";
function GetStartedNow() {
  useEffect(() => {
    Aos.init({ duration: 3000 });
  }, []);
  return (
    <main data-aos="fade-up"  className='bg-[#149954] rounded-2xl flex items-center justify-center flex-col gap-3 h-96 relative'>
    <img src={curvedLeft} alt='circle left'  className='absolute left-0 h-full '/>
    <img src={curvedRight} alt='circle right' className='absolute right-0 h-full '/>
    <div className='flex flex-col gap-6 text-center relative'>
    <h1 className='text-white font-medium font-roboto lg:text-[2.25rem] text-xl'> Get Started Now!</h1>
    <p className='text-white font-cabin text-base lg:w-[48ch] relative'>Gain access to comprehensive solutions designed for your business growth. Click Get Started Now to explore the tools that add real value to your real estate operations</p>

    <Link to="/getStarted" className="bg-[#FAFEFC] font-cabin font-semibold text-[#149954] px-16 p-3 rounded-lg  w-fit mx-auto my-0 cursor-pointer">
       Get Started
        </Link>
    </div>

    </main>
  )
}

export default GetStartedNow
